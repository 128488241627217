import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/non-shared/auth.service';
import {USER_PERMISSIONS} from '../constants/user.constants';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        // Get the current user data from the local storage
        const user = this.authService.getCurrentUser();

        // If user exist
        if (user && user.expiration) {
            // And user's session has not expired
            if (user.expiration > Date.now()) {
                return this.canAccess(route, user);
            } else {
                // Otherwise, hes sessions expired
                this.router.navigate(['/login']);
                return false;
            }
        }

        // If the user does not exist at all hes not authenticated at all
        this.router.navigate(['/login']);
        return false;
    }

    /**
     * Check if the user can access the specific route
     * @param route
     * @param user
     * @private
     */
    private canAccess(route, user) {
        let path: string = route.routeConfig.path;
        // In case of user that tries to export info about optout
        if (path === 'static-content') {
            path = 'staticContent';
        }
        if (USER_PERMISSIONS[path] != undefined) {
            if (!user.permissions[USER_PERMISSIONS[path]].Access) {
                this.router.navigate(['/dashboard/brands']);
            }
            return user.permissions[USER_PERMISSIONS[path]].Access;
        }

        return true;
    }
}
