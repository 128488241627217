import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/non-shared/auth.service';
import { UtilityService } from '../services/shared/utility.service';
import { CMS_DEVELOP_ORIGIN } from '../constants/general.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../material-snackbars/error-snackbar/error-snackbar.component';
import { SnackbarService } from '../services/non-shared/snackbar.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthenticationService,
        private utilityService: UtilityService,
        private _snackBar: MatSnackBar,
        private snackbarService: SnackbarService,
        private router: Router,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user = this.authService.getCurrentUser();
        let cloned = req.clone({
            url: this.utilityService.isLocalEnv() && !req.url.includes('https://storage.googleapis.com') ? CMS_DEVELOP_ORIGIN + req.url : req.url
        });
        if (!!user && !!user.token) {
            cloned = cloned.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
            return this.handleRequest(next, cloned);
        }

        return next.handle(cloned);
    }

    /**
     * The request handler
     * @param next      The HTTP handler
     * @param cloned    The cloned data with the changes (gdev, or JWT token added)
     */
    private handleRequest(next: HttpHandler, cloned): Observable<HttpEvent<any>> {
        return next.handle(cloned).pipe(tap(() => {
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                //make sure the error is not empty
                let error: string = err.error.error === undefined || err.error.error === "" ? err.error.message : err.error.error;
                error = error == undefined || error == "" ? "Unknown Error" : error;
                // Add the error message to the errors array
                if (this.snackbarService.addServerError(error)) {
                    // If session expired throw the user to login page
                    if (+err?.error?.errorCode === 101) {
                        this.router.navigate(['/login']);
                    }

                    this._snackBar.openFromComponent(ErrorSnackbarComponent, {
                        verticalPosition: 'top',
                        duration: 5000,
                        panelClass: 'error-snackbar',
                        data: {
                            errors: this.snackbarService.getServersErrors()
                        }
                    }).afterDismissed()
                        .subscribe(info => {
                            if (info.dismissedByAction === false) {
                                this.snackbarService.resetServersErrors();
                            }
                        });
                }
            }
        }));
    }
}
