import { Injectable } from '@angular/core';
import {
  BUCKET_PREFIX, CMS_DEVELOP_HOSTNAME,
  CMS_DEVELOP_ORIGIN, IMAGE_NAME_UNUSED_CHARACTERS
} from '../../constants/general.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  /**
   * Return boolean value to the question: is it localhost?
   */
  public isLocalEnv() {
    return window.location.href.indexOf("localhost") > -1;
  }

  /**
   * Returns the prefix to the path of the needed bucket.
   * @param host  {string | null}  The host itself, can be null which means it's spark-cms asset
   */
  public getPrefixBucketPath(host: string | null = null) {
    // It's spark asset
    if (host == null) {
      host = this.isLocalEnv() ? CMS_DEVELOP_HOSTNAME : window.location.host;
      host += "/images/"
    }

    return `${BUCKET_PREFIX}${environment.bucket_name}/${host}`;
  }

    /**
   * Returns the prefix to the path of the needed bucket use in media center module.
   * @param imageUrl  {string}  The full path URL of the image.
   * @param addedPath {string}  Placeholder to allow add extara path inside the current URL. added only after the last "/" 
   */
  public getImageUrlMediaCenter(imageUrl: string, addedPath = 'images'): string {
    // const modifiedUrl = imageUrl.replace(/\/([^/]+)$/, `/${addedPath}/$1`);
    const modifiedUrl = imageUrl.replace(/\/([^/]+)$/, `$1`);

    // return `${environment.bucket_name}/${modifiedUrl}`;
    return `${environment.bucket_name}/${imageUrl}`;
  }

  /**
   * Sort an array - ASC.
   * @param arrayToSort {Array<any>}  The array we would to sort
   * @param sortBy {string}   Sort by value
   */
  public sortArrByASC(arrayToSort: Array<any>, sortBy: string): void {
    arrayToSort.sort(function (elem_a, elem_b) {
      if (elem_a[sortBy] == undefined || elem_b[sortBy] == undefined) return;
      const string_a: string | number = typeof elem_a[sortBy] == "string" ? elem_a[sortBy].toLowerCase() : elem_a[sortBy];
      const string_b: string | number = typeof elem_b[sortBy] == "string" ? elem_b[sortBy].toLowerCase() : elem_b[sortBy];

      if (string_a < string_b) { return -1; }
      if (string_a > string_b) { return 1; }
      return 0;
    });
  }

  /**
   * Sort an array - DESC.
   * @param arrayToSort {Array<any>}  The array we would to sort
   * @param sortBy {string}   Sort by value
   */
  public sortArrByDESC(arrayToSort: Array<any>, sortBy: string): void {
    arrayToSort.sort(function (elem_a, elem_b) {
      if (elem_a[sortBy] == undefined || elem_b[sortBy] == undefined) return;
      const string_a: string | number = typeof elem_a[sortBy] == "string" ? elem_a[sortBy].toLowerCase() : elem_a[sortBy];
      const string_b: string | number = typeof elem_b[sortBy] == "string" ? elem_b[sortBy].toLowerCase() : elem_b[sortBy];

      if (string_a < string_b) { return 1; }
      if (string_a > string_b) { return -1; }
      return 0;
    });
  }

  /**
   * Remove no needed characters
   * @param name  {string}
   */
  public fixImageName(name: string) {
    let newName: string = '';

    for (let i = 0; i < name.length; i++) {
      if (!IMAGE_NAME_UNUSED_CHARACTERS.includes(name.charAt(i))) {
        newName += name.charAt(i);
      }
    }

    if (newName.startsWith("_") || newName.startsWith(" ") || newName.startsWith(".") || newName.startsWith("-")) {
      newName = newName.slice(1);
    }

    let fileNameWithoutExt: string = newName.split(".")[0];
    if (fileNameWithoutExt.endsWith("_") || fileNameWithoutExt.endsWith(" ") || fileNameWithoutExt.endsWith(".") || fileNameWithoutExt.endsWith("-")) {
      fileNameWithoutExt = fileNameWithoutExt.slice(0, -1);
    }

    fileNameWithoutExt = fileNameWithoutExt.substring(0, 31);
    fileNameWithoutExt = fileNameWithoutExt.toLowerCase();

    fileNameWithoutExt = fileNameWithoutExt.replace(/[ ]|_/g, '-');

    newName = `${fileNameWithoutExt}.${newName.split(".")[1]}`;

    return newName;
  }

  /**
   * Get time and return how long passed, days, minutes, seconds etc..
   * @param time  {string}  Time as string example: "2022-11-02T14:56:03+0000"
   */
  public getTimePassedFromCurrentDate(time: string): string {
    const timeInMS: number = Math.floor(Number(new Date(time)));
    const currentTimeInMS: number = Math.floor(Date.now())

    return this.getTimePassedHumanFormat(currentTimeInMS - timeInMS);
  }

  /**
   * Return human-readable text of seconds.
   * For example: 60000 seconds -> 1 minute
   *              120000 seconds -> 2 minutes
   * @param milliseconds {number}
   * @private
   */
  private getTimePassedHumanFormat(milliseconds: number): string {
    function numberEnding(number) {
      return (number > 1) ? 's' : '';
    }

    let temp = Math.floor(milliseconds / 1000);
    let years = Math.floor(temp / 31536000);
    if (years) {
      return years + ' year' + numberEnding(years);
    }

    let days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      return days + ' day' + numberEnding(days);
    }
    let hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return hours + ' hour' + numberEnding(hours);
    }
    let minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return minutes + ' minute' + numberEnding(minutes);
    }
    let seconds = temp % 60;
    if (seconds) {
      return seconds + ' second' + numberEnding(seconds);
    }
    return 'less than a second';
  }

  public getAcronym(editor: string) {
    let arrName = editor.split(" ");
    let firstName = arrName[0].charAt(0).toUpperCase();
    let lastName = arrName[1].charAt(0).toUpperCase()
    return firstName + lastName;
  }

  public scrollToTop(anchor: string) {
    document.getElementsByClassName(anchor)[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
