import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ErrorSnackbarModel } from '../../models/snackbar.model';
import { SnackbarService } from '../../services/non-shared/snackbar.service';

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent {

  constructor(
    private _snackRef: MatSnackBarRef<ErrorSnackbarComponent>,
    private snackbarService: SnackbarService,
    @Inject(MAT_SNACK_BAR_DATA) public data: ErrorSnackbarModel
  ) { }

  ngOnInit() {
    if (this.data?.errors?.some(err => err?.includes(' is also working on this page'))) {
        this.data.errors = this.data.errors.filter(err => !err.includes(' is also working on this page'));
    }
  }

  public closeSnackbar() {
    this.snackbarService.resetServersErrors();
    this._snackRef.dismiss();
  }
}
