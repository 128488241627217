import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { USER_SESSIONS_EXPIRATION_TIME } from '../../constants/user.constants';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { Destroyable } from '../utils/destroyable';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends Destroyable {
    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject('LOCALSTORAGE') private localStorage: Storage
    ) {
        super();
    }

    loginRequest(username: string, password: string) {
        const loginUrl: string = `/api/cms/login`;
        const body: object = {
            username: username,
            password: password
        };
        return this.http.post<any>(loginUrl, body);
    }

    login(username: string, password: string): Observable<boolean> {
        return this.loginRequest(username, password)
            .pipe(
                map(response => {
                    if (!!response && !response.hasOwnProperty('error')) {
                        // Remove the old currentUser
                        if (this.localStorage.getItem('currentUser')) {
                            this.localStorage.removeItem('currentUser');
                        }
                        // Otherwise, save the current user token and expiration day
                        this.localStorage.setItem('currentUser', JSON.stringify({
                            token: response.token,
                            expiration: Date.now() + USER_SESSIONS_EXPIRATION_TIME,
                            name: response.name,
                            permissions: response.permissions,
                            role: response.role
                        }));
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError(error => {
                    return of(false);
                })
            );
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.localStorage.removeItem('currentUser');
        const logoutUrl: string = `/api/cms/logout`;

        this.http.post<any>(logoutUrl, null).toPromise().then(() => {
            window.location.href = '/login';
        });
    }

    getCurrentUser(): any {
        const currentUser = this.localStorage.getItem('currentUser');
        return currentUser ? JSON.parse(this.localStorage.getItem('currentUser')) : null;
    }

    getUserPermissions(): any {
        const user = this.getCurrentUser();
        return user.permissions;
    }

    userHasPermission(permissions: object): boolean {
        const userPermissions = this.getUserPermissions();
        let hasPermission: boolean = true;
        Object.keys(permissions).forEach(key => {
            if (!userPermissions[key] || userPermissions[key][permissions[key]] === false) {
                hasPermission = false;
            }
        });

        return hasPermission;
    }
}
