import { AvailablePlatformsModel, AvailableRenderFormsModel, IframeClassPerPlatformModel } from '../models/general.model';
import { environment } from '../../environments/environment';

export const SECURE_PROTOCOL: string = "https://";
export const CMS_DEVELOP_HOSTNAME: string = `${environment.env_subdomain}.spark-cms.com`;
export const CMS_DEVELOP_ORIGIN: string = environment.env_subdomain === 'localhost' ? 'http://localhost:8000' : SECURE_PROTOCOL + CMS_DEVELOP_HOSTNAME;

export const SHOW_LOADER_CALLS: Array<string> = [
    '/api/cms/login',
    // '/api/cms/websites/list',
    '/api/cms/modules/available',
    '/api/cms/pageVersion/structure',
    '/api/cms/brandSettings/structure',
    // '/api/cms/pages/list',
];
export const SHOW_LOADER_CALLS_ON_REPEAT: Array<string> = [
    '/api/cms/migrate/changeWebsiteURL',
    // '/api/cms/website/save',
    // '/api/cms/page/save',

];
export const ADD_OR_EDIT_WEBSITE_OR_PAGE_ANIMATION_DURATION: number = 500;
export const TABLE_DATA_REFRESH_INTERVAL: number = 60 * 1000; // 1 minute
export const BUCKET_PREFIX: string = 'https://storage.googleapis.com/';
export const AVAILABLE_PLATFORMS: AvailablePlatformsModel = {
    desktop: "desktop",
    tablet: "tablet",
    mobile: "mobile"
};
export const TABLET_MAX_WIDTH: number = 766;
export const MOBILE_MAX_WIDTH: number = 479;
export const AVAILABLE_RENDER_FORMS: AvailableRenderFormsModel = {
    addOrEditPage: 0,
    addOrEditWebsite: 1,
    editPageStructure: 2
}
export const SUCCESS_DIALOG_LIVE_TIME: number = 3000;
export const IFRAME_CLASS_PER_PLATFORM: IframeClassPerPlatformModel = {
    desktop: 'desktop-preview',
    tablet: 'tablet-preview',
    mobile: 'mobile-preview'
}
export const MATERIAL_NOTIFICATION_DURATION: number = 3000;
export const IMAGE_NAME_UNUSED_CHARACTERS: Array<string> = ['#', '&', '%', '{', '}', '\\', '/', '<', '>', '*', '?', '$', '!', '`', '"', "'", ':', '@', '^'];



type regexStrignType = 'onlyNumbers' | 'onlyEnglishLetters' | 'onlyHebrewLetters' | 'withSpecialCharacters' | 'withHebrewEnglishLetters';

export function getRegexPattern(regexPattern: regexStrignType | RegExp) {
    switch (regexPattern) {
        case 'onlyNumbers':
            return '^[0-9]+$';
        // return /[^0-9]/g;
        case 'onlyEnglishLetters':
            return `^[a-zA-Z]+$`;
        //  /[^a-zA-Z]+/g;
        case 'onlyHebrewLetters':
            return '^[א-ת]+$';
        // return /[^א-ת]/g;
        case 'withSpecialCharacters':
            return '^[a-zA-Z0-9_~!@#$%^&*()-=+]+$';
        // return /[^a-zA-Z0-9_~!@#$%^&*()-=+]/g;
        case 'withHebrewEnglishLetters':
            return '^[\u0590-\u05FFa-zA-Z0-9-_() \s]*$';
        // return /^[\u0590-\u05FFa-zA-Z0-9\s]*$/
        default:
            return `^[a-zA-Z]+$`;
    }
}

