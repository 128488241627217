import {HttpInterceptor, HttpResponse} from '@angular/common/http';
import {LoaderService} from '../services/non-shared/loader.service';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SHOW_LOADER_CALLS, SHOW_LOADER_CALLS_ON_REPEAT} from '../constants/general.constants';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private lastUrl: string = '';

    constructor(
        private loaderService: LoaderService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Show loader only on initial calls (after routing)
        const showLoader = SHOW_LOADER_CALLS.includes(req.url) && this.lastUrl !== req.url || SHOW_LOADER_CALLS_ON_REPEAT.includes(req.url);
        // Save the last url if it's a url we would like to show loader on it's call
        this.lastUrl = SHOW_LOADER_CALLS.includes(req.url) ? req.url : this.lastUrl;
        if (!!showLoader) {
            showLoader ? this.loaderService.setDataReceived(false) : null;
            this.loaderService.setShowLoader(true)
            return next
                .handle(req)
                .pipe(
                    tap((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.loaderService.setShowLoader(false)

                            showLoader ? this.loaderService.setDataReceived(true) : null;
                        }
                    })
                );
        } else if (req.url.includes('api') && this.lastUrl !== req.url) {
            this.loaderService.setLocalDataReceived(false);
            return next
                .handle(req)
                .pipe(
                    tap((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.loaderService.setLocalDataReceived(true);
                        }
                    })
                );
        }

        return next.handle(req);
    }
}
