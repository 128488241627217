import {ChangeDetectorRef, Component} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import {LoaderService} from './services/non-shared/loader.service';
import {Observable} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public showLoader$: Observable<boolean>;

  constructor(
      private router: Router,
      private loaderService: LoaderService,
      private cd: ChangeDetectorRef,
      private titleService: Title
  ) {
    this.getLoader().then(() => {
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void{
    this.titleService.setTitle('Spark');

    // Show or hide spinner based on router changes
    this.router.events.subscribe((routerEvent : RouterEvent) => {
      this.navigationHandler(routerEvent);
    });

    // Show or hide spinner based on responses we didn't received yet
    this.loaderService.getDataReceived().subscribe((isDataReveived: boolean) => {
      // If the request has been finished, and we got the response, remove the loader, otherwise, show it
      this.changeLoaderState(!isDataReveived);
    });
  }

  private changeLoaderState(value): void{
      this.loaderService.setShowLocalLoader(value);
      this.cd.detectChanges();
  }

  /**
   * Shows and hides the loading spinner during RouterEvent changes
   * @param event {RouterEvent} The router event
   */
  navigationHandler(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.changeLoaderState(true);
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.changeLoaderState(false);
    }
  }

  /**
   *  Get the loader value
   */
  private async getLoader(): Promise<void>{
    this.showLoader$ = this.loaderService.getShowLoader();
  }
}
