import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {GuardsModule} from './guards/guards.module';
import {CustomMaterialModule} from './modules/custom-material/custom-material.module';
import {AppRoutingModule} from './app-routing.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoginComponent} from './pages/login/login.component';
import {WarningPopupComponent} from './popups/warning-popup/warning-popup.component';
import {LottieModule} from 'ngx-lottie';
import {SuccessPopupComponent} from './popups/success-popup/success-popup.component';
import {SuccessSnackbarComponent} from './material-snackbars/success-snackbar/success-snackbar.component';
import {ErrorSnackbarComponent} from './material-snackbars/error-snackbar/error-snackbar.component';
import {NotificationSnackbarComponent} from './material-snackbars/notification-snackbar/notification-snackbar.component';
import {ErrorPopupComponent} from './popups/error-popup/error-popup.component';
import {ActionsSnackbarComponent} from './modules/media-actions/brands/actions-snanckbar/actions-snanckbar.component';
import {CommonModule, DatePipe} from '@angular/common';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import player from 'lottie-web';
import {LoaderModule} from './modules/loader/loader.module';
import {CodeEditorModule} from '@ngstack/code-editor';


export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        WarningPopupComponent,
        SuccessPopupComponent,
        SuccessSnackbarComponent,
        ErrorSnackbarComponent,
        NotificationSnackbarComponent,
        ErrorPopupComponent,
        ActionsSnackbarComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        CodeEditorModule.forRoot(),
        LottieModule.forRoot({player: playerFactory}),
        BrowserModule,
        BrowserAnimationsModule,
        GuardsModule,
        FontAwesomeModule,
        CustomMaterialModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule
    ],
    providers: [DatePipe],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
