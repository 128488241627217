import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../services/non-shared/auth.service';
import { Destroyable } from '../../services/utils/destroyable';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Destroyable implements OnInit {
    public loginForm: FormGroup;
    public loading = false;
    public errorOccur = false;
    public cmsName = 'SPARK';
    @ViewChild('passwordInput') passwordInput: ElementRef;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        super();
    }

    ngOnInit() {
        this.createForm();
    }

    private createForm() {
        this.loginForm = new FormGroup({
            username: new FormControl(''),
            password: new FormControl(''),
        });
    }

    login() {
        this.loginRequest();
    }

    private loginRequest() {
        this.loading = true;
        this.errorOccur = false;
        const username = this.loginForm.get('username').value;
        const password = this.loginForm.get('password').value;
        const res = this.authenticationService.login(username, password);

        res?.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            if (!response) {
                this.errorOccur = true;
                this.loading = false;
            } else {
                this.router.navigate(['/dashboard']).then(() => {
                    this.loading = false;
                });
            }
        });
    }

    preventSpaces(inputElement) {
        const inputValue = inputElement.value;
        if (inputValue?.includes(' ')) {
            inputElement.value = inputValue.replace(/\s/g, ''); // Remove spaces
        }
    }

    preventSpaceKey(event) {
        if (event.key === ' ') {
            event.preventDefault(); // Prevent space key press
        }
    }

    public toggleShowPassword() {
        this.passwordInput.nativeElement.setAttribute('type', 'text');
    }

    public toggleHidePassword() {
        this.passwordInput.nativeElement.setAttribute('type', 'password');
    }
}

