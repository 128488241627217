import {LoaderComponent} from './loader.component';
import {NgModule} from '@angular/core';
import {NgClass} from '@angular/common';

@NgModule({
    declarations: [LoaderComponent],
    exports: [LoaderComponent],
    imports: [
        NgClass
    ]
})
export class LoaderModule {}
