<form class="login-form" [formGroup]="loginForm" fxFlex="30%" fxFlex.sm="50%" fxFlex.xs="90%">
    <mat-card class="material-card">
        <div class="login-form-wrapper">
            <mat-card-content>
                <div class="headers-wrapper">
                    <h1 class="login-form-header">
                        WELCOME TO {{cmsName}}
                    </h1>
                    <h2 class="login-form-sub-header">
                        Log in
                    </h2>
                </div>
                <input class="login-input-box username-input" id="emailInput" matInput placeholder="Username"  formControlName="username" autocomplete="username" #username
                    type="text">

                <div class="input-password-container">
                    <input class="login-input-box password-input"
                           #passwordInput
                           id="passwordInput"
                           matInput
                           placeholder="Password"
                           formControlName="password"
                           type="password"
                           #password
                           autocomplete="current-password"
                           (input)="preventSpaces(this);"
                           (keydown)="preventSpaceKey($event);">

                    <img src="./assets/login/show-password.png"
                         (mousedown)="toggleShowPassword()"
                         (mouseup)="toggleHidePassword()"
                         class="toggle-password"/>
                </div>

                <span *ngIf="errorOccur" class="login-error">
                    Sorry, your input is invalid.
                    Please try again.
                </span>

                </mat-card-content>
                <mat-card-actions class="login-actions">
                    <button class="login-button" mat-raised-button id="login" color="primary" [disabled]="loginForm.invalid || loading || (username.value.length === 0 || password.value.length === 0)"
                        (click)="login()">Log in</button>
                    <p class="forgot-password">
                        Forgot Password? Please contact <a class="forgot-password-email" href="mailto: support.dev@rank.com">support.dev@rank.com</a>
                    </p>
                </mat-card-actions>
        </div>
    </mat-card>

    <div class="image-wrapper">
        <img class="login-background-image" src="./assets/login/login-screen.png"/>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</form>

