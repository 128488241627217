import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private showLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showLocalLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**@description spinner for http calls that will display in mat-content section if using mat-tab-group - when pass between tabs  */ 
  private tabsSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly getTabsSpinner$ = this.tabsSpinnerSubject.asObservable();

  private footerTabSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly getFooterTabSpinner$ = this.footerTabSpinnerSubject.asObservable();

  // An indicator for every request from the backend
  private dataReceived$ = new BehaviorSubject<boolean>(true);
  private localDataReceived$ = new BehaviorSubject<boolean>(true);
  public opacity: boolean = false;

  constructor() { }

  public setDataReceived(value): void {
    this.dataReceived$.next(value)
  }

  public setLocalDataReceived(value): void {
    this.localDataReceived$.next(value)
  }

  public getDataReceived(): BehaviorSubject<boolean> {
    return this.dataReceived$;
  }

  public setShowLoader(value, withOpacity = false): void {
    this.opacity = withOpacity;
    this.showLoader$.next(value)
  }

  public setShowLocalLoader(value, withOpacity = false): void {
    this.opacity = withOpacity;
    this.showLocalLoader$.next(value)
  }

  public getShowLoader(): BehaviorSubject<boolean> {
    return this.showLoader$;
  }
  public getShowLocalLoader(): BehaviorSubject<boolean> {
    return this.showLocalLoader$;
  }

  /**@description spinner for http calls that will display in mat-content section if using mat-tab-group - when pass between tabs  */ 
  public setTabsSpinner(show: boolean): void {
    this.tabsSpinnerSubject.next(show);
  }
  public setFooterTabSpinner(show: boolean): void {
    this.footerTabSpinnerSubject.next(show);
  }
}
