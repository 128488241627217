import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private serverErrors: Array<string> = [];

  constructor() { }

  public addServerError(err: string): boolean{
    // Unique errors array
    if(!this.serverErrors.includes(err)){
      this.serverErrors.push(err);
      return true;
    } else {
      return false;
    }
  }

  public getServersErrors(): Array<string>{
    return this.serverErrors.slice(-3);
  }

  public resetServersErrors(){
    this.serverErrors = [];
  }

  public containSessionError() {
    for (let error of this.serverErrors) {
      if (error.toLowerCase().includes("session") || error.toLowerCase().includes("token")) {
        return true;
      }
    }

    return false;
  }
}
