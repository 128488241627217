import {UserPermissionsModel} from '../models/user.model';

export const USER_SESSIONS_EXPIRATION_TIME: number = 1000 * 60 * 60 * 2; // 2 hours

export const USER_PERMISSIONS: UserPermissionsModel = {
    optOut: "Opt_Out",
    admin: "Admin",
    optIn: "Opt_In",
    campaignEditor: "Campaign_Editor",
    staticContent: "Static Content"
}
